import { Auth0Provider } from "@auth0/auth0-react";
import { ChakraProvider } from "@chakra-ui/react";
import * as Sentry from "@sentry/react";
import { Provider } from "react-redux";

import {
  AUDIENCE,
  CLIENT_ID,
  DOMAIN,
  REDIRECT_URI,
} from "./constants/auth0Consts";
import { store } from "./redux/store";
import Routes from "./Routes";
import { campfireHostingTheme } from "./theme/campfireHostingTheme";
import { getEnvironmentPrefix } from "./utils/environment";

const DSN =
  "https://bef08baf9f4c4b9ebd1d164143c8fbc2@o4504971331305472.ingest.sentry.io/4504971339104256";

Sentry.init({
  dsn: DSN,
  environment: getEnvironmentPrefix(true),
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

function App() {
  return (
    <Provider store={store}>
      <Auth0Provider
        domain={DOMAIN}
        clientId={CLIENT_ID}
        redirectUri={REDIRECT_URI}
        audience={AUDIENCE}
      >
        <ChakraProvider cssVarsRoot={undefined} theme={campfireHostingTheme}>
          <Routes />
        </ChakraProvider>
      </Auth0Provider>
    </Provider>
  );
}

export default App;
